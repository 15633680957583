import React, { useState, useEffect } from "react";
import "./App.scss";
import { Route, BrowserRouter } from "react-router-dom";
import Landing from "./layout/pages/Landing/Landing";
import Login from "./Admin/Login/Login";

import Hub from "./Admin/Hub/Hub";
import PrivateRoute from "./PrivateRoute";
import AuthFunctions from "./AuthFunctions";

import LandingRouter from "./layout/LandingRouter";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const App = () => {
  const [user, setUser] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    const Auth = new AuthFunctions();
    setUser(Auth.getUser() || "");
    setToken(Auth.getToken() || "");
  }, []);

  return (
    <BrowserRouter>
      <React.Fragment>
        <PrivateRoute
          exact
          path="/hub"
          component={Hub}
          user={user}
          token={token}
        />
        {/* <PrivateRoute exact path="/newPage" component={newPage} user={user} token={token}/> */}
        <Route exact path="/login" render={() => <Login />} />

        <Route exact path="/" component={Landing} />

        <Route exact path="/games" component={LandingRouter} />
        <Route exact path="/projects" component={LandingRouter} />
        <Route exact path="/art" component={LandingRouter} />
        <Route exact path="/about" component={LandingRouter} />
        <Route exact path="/contact" component={LandingRouter} />
      </React.Fragment>
    </BrowserRouter>
  );
};

export default App;
